import React, { useState } from 'react';
import './App.css';
import imageList from './imageList.json';
import 'font-awesome/css/font-awesome.min.css';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { GetApp } from '@mui/icons-material';

function App() {
  const [selectedImage, setSelectedImage] = useState(null); // Selected image in modal
  const [openModal, setOpenModal] = useState(false); // Modal visibility state for "Download All"
  const [downloading, setDownloading] = useState(false); // Download state for "Download All"

  // Handle image click to open modal
  const handleImageClick = (image) => setSelectedImage(image);

  // Close image modal
  const closeImageModal = () => setSelectedImage(null);

  // Navigate to the next image in the list
  const goToNextImage = () => {
    const currentIndex = imageList.findIndex((img) => img.original === selectedImage.original);
    const nextIndex = (currentIndex + 1) % imageList.length; // Loop back to the first image
    setSelectedImage(imageList[nextIndex]);
  };

  // Navigate to the previous image in the list
  const goToPreviousImage = () => {
    const currentIndex = imageList.findIndex((img) => img.original === selectedImage.original);
    const prevIndex = (currentIndex - 1 + imageList.length) % imageList.length; // Loop to the last image
    setSelectedImage(imageList[prevIndex]);
  };

  // Open "Download All" confirmation modal
  const handleDownloadAllClick = () => setOpenModal(true);

  // Confirm "Download All" and trigger downloads
  const handleConfirmDownloadAll = () => {
    setDownloading(true);
    imageList.forEach((image) => {
      const link = document.createElement('a');
      link.href = `${process.env.PUBLIC_URL}/${image.original}`;
      link.download = image.original;
      link.click();
    });
    setOpenModal(false);
    setDownloading(false);
  };

  // Cancel "Download All"
  const handleCloseDownloadModal = () => setOpenModal(false);

  // Download the currently displayed image in modal
  const handleDownloadCurrentPhoto = () => {
    if (selectedImage) {
      const link = document.createElement('a');
      link.href = `${process.env.PUBLIC_URL}/${selectedImage.original}`;
      link.download = selectedImage.original;
      link.click();
    }
  };

  return (
    <div className="app-container">
      <header className="app-header">
        <h1 className="header-title">Galeria</h1>
        <div className="header-actions">
          <IconButton aria-label="Download All" onClick={handleDownloadAllClick}>
            <GetApp />
          </IconButton>
        </div>
      </header>

      {/* Gallery Section */}
      <div className="gallery">
        {imageList.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img
              src={`${process.env.PUBLIC_URL}/${image.thumbnail}`}
              alt={`Image ${index + 1}`}
              onClick={() => handleImageClick(image)}
            />
          </div>
        ))}
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="modal" onClick={closeImageModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <IconButton
              className="nav-button prev-button"
              onClick={goToPreviousImage}
              aria-label="Previous image"
              style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                backgroundColor: '#333',
                color: '#fff',
                borderRadius: '50%',
              }}
            >
              <ArrowBack />
            </IconButton>

            <img
              className="modal-image"
              src={`${process.env.PUBLIC_URL}/${selectedImage.original}`}
              alt="Fullscreen"
            />

            <IconButton
              className="nav-button next-button"
              onClick={goToNextImage}
              aria-label="Next image"
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                backgroundColor: '#333',
                color: '#fff',
                borderRadius: '50%',
              }}
            >
              <ArrowForward />
            </IconButton>

            <Button
              className="download-button"
              onClick={handleDownloadCurrentPhoto}
              style={{
                position: 'absolute',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '#333',
                color: '#fff',
                padding: '12px 20px',
                fontSize: '16px',
                borderRadius: '5px',
              }}
            >
              <GetApp style={{ marginRight: '8px' }} />
            
            </Button>
          </div>
        </div>
      )}

      {/* "Download All" Confirmation Modal */}
      <Dialog open={openModal} onClose={handleCloseDownloadModal}>
  <DialogTitle> Download Completo </DialogTitle>
  <DialogContent>
    <p>Quer transferir todas as fotos?</p>
    {/* Embedded iframe */}
    <div
      style={{
        position: 'relative',
        width: '500px',
        height: '70&',
        paddingBottom: '56.25%', // Aspect ratio 16:9
      }}
    >
      <iframe
        src="https://cleverti-my.sharepoint.com/personal/joao_vilares_cleverti_com/_layouts/15/embed.aspx?UniqueId=ade01513-4af0-4cf5-b046-bb17f016805d"
        width="100%"
        height="100%"
        frameBorder="0"
        scrolling="no"
        allowFullScreen
        title="casamentoJJ"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      ></iframe>
    </div>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDownloadModal} color="black">
      Sair
    </Button>

  </DialogActions>
</Dialog>

    </div>
  );
}

export default App;
